import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'

import pic08 from '../assets/images/pic08.jpg'
import pic09 from '../assets/images/pic09.jpg'
import pic10 from '../assets/images/pic10.jpg'

const Landing = (props) => (
    <Layout>
        <Helmet>
            <title>Portfolio</title>
            <meta name="description" content="Landing Page" />
        </Helmet>

        <BannerLanding />

        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>Website Management</h2>
                    </header>
                    <p>Looking for a custom professional e commerce website that is professional and affordable?!</p>
                    <p>Design, development, training, and websites maintantance!</p>

                </div>
            </section>
            <section id="two" className="spotlights">
                <section>
                    <Link to="/generic" className="image">
                        <img src={pic08} alt="gradient" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Restaurant &amp; Coffee Shop</h3>
                            </header>
                            <p>The restaurant's website is the center of its online presence. If you have a restaurant, it is essential that you have a website that makes your mouth water, both for its attractive design and useful information.</p>
                    
                            <ul className="actions">
                                <li><Link to="/food" className="button">Learn more</Link></li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section>
                    <Link to="/generic" className="image">
                        <img src={pic09} alt="abstract" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>E-commerce Stores</h3>
                            </header>
                            <p>A company that sells any type of product or services, whether to increase its physical store, or even start a new business, increase possibilities for success. Among several advantages of having an online store we cound mention Credibility, Easy communication and access with clients, New business opportunities, 24h Presentation and sales, Value of the brand, Create a strong relatioship with costumers.</p>

                            <ul className="actions">
                                <li><Link to="/ecommerce" className="button">Learn more</Link></li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section>
                    <Link to="/blog" className="image">
                        <img src={pic10} alt="gradient" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Blog</h3>
                            </header>
                            <p>Blog is a tool that has the potential to help a company gain authority, appear at the top of search engines and, of course, sell more.</p>
                            <ul className="actions">
                                <li><Link to="/blog" className="button">Learn more</Link></li>
                            </ul>
                        </div>
                    </div>
                </section>
            </section>
        </div>

    </Layout>
)

export default Landing